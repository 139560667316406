import { DatoCartProduct, DatoPurchasedProduct } from "@dato/types"
import { LineItem, CheckoutData } from "@shopify/types"
import {
  CartProducts,
  CartItem,
  ProductMap,
  LocalCheckout,
  PurchasedProducts,
} from "./types"
import { getIn } from "@utils/index"
import * as R from "ramda"

export const graphQLRequest = async (fn, options) => {
  try {
    const { data, errors } = await fn(options)
    return { data, errors }
  } catch (error) {
    return { data: null, errors: error }
  }
}

export const getMissingCartProductIds = (
  checkoutData: CheckoutData,
  cartProducts: CartProducts
) => {
  const lineItems = getIn(checkoutData, "lineItems.edges", []) as Array<{
    node: LineItem
  }>

  return R.pipe(
    R.map(({ node: lineItem }: { node: LineItem }) => {
      if (!cartProducts[lineItem.variant.sku]) {
        const datoProductId = lineItem.customAttributes.find(
          ({ key }) => key === "_datoProductId"
        )
        return datoProductId
          ? {
              datoProductId: datoProductId.value,
              variantSku: lineItem.variant.sku,
            }
          : undefined
      }
      return undefined
    }),
    R.reject(R.isNil)
  )(lineItems) as Array<{ datoProductId: string; variantSku: string }>
}

export const formatDatoCartProductsData = (
  datoData,
  productMap: ProductMap
) => {
  const products: Array<DatoCartProduct> = getIn(datoData, "allProducts", [])

  return products.reduce((cartProducts, product) => {
    const sku = productMap[product.id]
    const variantData =
      product.variants.find(({ variantSku }) => variantSku === sku) || {}
    const variantOptions = getIn(variantData, "options", [])

    const cartProduct = {
      datoProductId: product.id,
      datoVariantId: getIn(variantData, "id"),
      shopifyProductId: getIn(variantData, "shopifyData.parentId"),
      shopifyVariantId: getIn(variantData, "shopifyData.variantId"),
      productSlug: product.slug,
      productName: product.productName,
      variantName: getIn(variantData, "variantName"),
      sku: getIn(variantData, "variantSku"),
      options: variantOptions.map(({ optionType, optionValue }) => ({
        optionType: optionType.name,
        optionValue,
      })),
      price: getIn(variantData, "price"),
      compareAtPrice: getIn(variantData, "compareAtPrice"),
      image: getIn(product, "images.0.url"),
    }

    cartProducts[cartProduct.sku] = cartProduct
    return cartProducts
  }, {}) as CartProducts
}

export const getLocalCheckoutProps = (checkoutData: CheckoutData) => ({
  id: getIn(checkoutData, "id"),
  currencyCode: getIn(checkoutData, "subtotalPriceV2.currencyCode"),
  subtotal: getIn(checkoutData, "lineItemsSubtotalPrice.amount"),
  total: getIn(checkoutData, "subtotalPriceV2.amount"),
})

export const buildLocalCheckout = (
  checkoutData: CheckoutData,
  cartProducts: CartProducts
) => {
  const lineItems = getIn(checkoutData, "lineItems.edges", []) as Array<{
    node: LineItem
  }>
  const localCheckoutItems = R.pipe(
    R.map(({ node: lineItem }) => {
      const cartProduct = cartProducts[lineItem.variant.sku]

      if (!cartProduct) {
        return undefined
      }

      return {
        ...cartProduct,
        quantity: lineItem.quantity,
      }
    }),
    R.filter((item) => !!item)
  )(lineItems)

  return {
    ...getLocalCheckoutProps(checkoutData),
    items: localCheckoutItems,
  } as LocalCheckout
}

export const createCheckoutLineItem = (cartItem: CartItem) => ({
  customAttributes: [
    { key: "_datoProductId", value: cartItem.datoProductId },
    { key: "_datoVariantId", value: cartItem.datoVariantId },
    { key: "_productSlug", value: cartItem.productSlug },
    { key: "_sku", value: cartItem.sku },
    ...(cartItem.productCategory
      ? [
          {
            key: "_productCategory",
            value: cartItem.productCategory[0].name,
          },
        ]
      : []),
    ...(cartItem.productSubcategory
      ? [
          {
            key: "_productSubCategory",
            value: cartItem.productSubcategory.name,
          },
        ]
      : []),
    ...(cartItem.productSeatCategory
      ? [
          {
            key: "_productSeatCategory",
            value: cartItem.productSeatCategory.name,
          },
        ]
      : []),
    ...(cartItem.productAttributes
      ? [
          {
            key: "_productAttributes",
            value: cartItem.productAttributes,
          },
        ]
      : []),
    ...(cartItem.sku
      ? [
          {
            key: "_sku",
            value: cartItem.sku,
          },
        ]
      : []),
    { key: "_productPrice", value: cartItem.price },
  ],
  variantId: btoa(cartItem.shopifyVariantId),
  quantity: cartItem.quantity,
})

export const getLineItemFromCheckoutData = (
  variantId: string,
  lineItems: Array<{ node: LineItem }>
) => {
  return lineItems.find(
    (item: { node: LineItem }) =>
      getIn(item, "node.variant.id") === btoa(variantId)
  )
}

export const partitionLineItemsByVariantId = (
  lineItems: Array<{ node: LineItem }>,
  variantIds: Array<string>
) => {
  return R.pipe(
    R.map(({ node }: { node: LineItem }) => ({
      customAttributes: R.map((customAttribute) =>
        R.pick(["key", "value"], customAttribute)
      )(getIn(node, "customAttributes")),
      variantId: getIn(node, "variant.id"),
      quantity: getIn(node, "quantity"),
    })),
    R.partition(
      ({ variantId: thisVarianId }) => variantIds.indexOf(thisVarianId) !== -1
    )
  )(lineItems)
}

export const buildPurchasedProducts = (
  products: Array<DatoPurchasedProduct>,
  productMap: ProductMap
) => {
  return products.reduce((purchasedProducts, product) => {
    const sku = productMap[product.id]
    const variants = product.variantsJson || product.variants || []
    const variantData =
      variants.find(({ variantSku }) => variantSku === sku) || {}
    const variantOptions = getIn(variantData, "options", [])

    const purchasedProduct = {
      id: product.id,
      slug: product.slug,
      productName: product.productName,
      make: getIn(product, "make.name"),
      model: getIn(product, "model.name"),
      year: product.year,
      image: getIn(product, "images[0].url"),
      variant: {
        id: getIn(variantData, "id"),
        variantSku: getIn(variantData, "variantSku"),
        options: variantOptions.map(({ optionType, optionValue }) => ({
          optionType: optionType.value,
          optionValue,
        })),
      },
    }

    purchasedProducts[purchasedProduct.variant.variantSku] = purchasedProduct
    return purchasedProducts
  }, {}) as PurchasedProducts
}
