import { Models } from "@rematch/core"
import checkout from "./checkout"
import customer from "./customer"

export interface RootModel extends Models<RootModel> {
  checkout: typeof checkout
  customer: typeof customer
}

export const models: RootModel = { checkout, customer }
