import gql from "graphql-tag"

export const ShopifyPricingPercentageFields = gql`
  fragment ShopifyPricingPercentageFields on PricingPercentageValue {
    percentage
  }
`

export const ShopifyMoneyV2Fields = gql`
  fragment ShopifyMoneyV2Fields on MoneyV2 {
    amount
    currencyCode
  }
`

export const ShopifyShippingRateFields = gql`
  ${ShopifyMoneyV2Fields}
  fragment ShopifyShippingRateFields on ShippingRate {
    title
    handle
    priceV2 {
      ...ShopifyMoneyV2Fields
    }
  }
`

export const ShopifyMailingAddressFields = gql`
  fragment ShopifyMailingAddressFields on MailingAddress {
    id
    firstName
    lastName
    company
    address1
    address2
    city
    country
    province
    zip
    phone
    formatted
  }
`

export const CheckoutUserErrorsFields = gql`
  fragment CheckoutUserErrorsFields on CheckoutUserError {
    code
    field
    message
  }
`

export const CheckoutDiscountFields = gql`
  ${ShopifyPricingPercentageFields}
  ${ShopifyMoneyV2Fields}
  fragment CheckoutDiscountFields on DiscountApplication {
    allocationMethod
    targetSelection
    targetType
    value {
      ...ShopifyPricingPercentageFields
      ...ShopifyMoneyV2Fields
    }
  }
`

export const CheckoutLineItemFields = gql`
  fragment CheckoutLineItemFields on CheckoutLineItem {
    id
    title
    quantity
    variant {
      id
      sku
      product {
        id
      }
    }
    customAttributes {
      key
      value
    }
  }
`

export const CheckoutFields = gql`
  ${ShopifyMoneyV2Fields}
  ${ShopifyShippingRateFields}
  ${ShopifyMailingAddressFields}
  ${CheckoutDiscountFields}
  ${CheckoutLineItemFields}
  fragment CheckoutFields on Checkout {
    id
    note
    webUrl
    completedAt
    shippingAddress {
      ...ShopifyMailingAddressFields
    }
    discountApplications(first: 100) {
      edges {
        node {
          ...CheckoutDiscountFields
        }
      }
    }
    lineItems(first: 100) {
      edges {
        node {
          ...CheckoutLineItemFields
        }
      }
    }
    lineItemsSubtotalPrice {
      ...ShopifyMoneyV2Fields
    }
    subtotalPriceV2 {
      ...ShopifyMoneyV2Fields
    }
    shippingLine {
      ...ShopifyShippingRateFields
    }
    totalTaxV2 {
      ...ShopifyMoneyV2Fields
    }
    totalPriceV2 {
      ...ShopifyMoneyV2Fields
    }
  }
`

export const ShopifyProductVariantFields = gql`
  ${ShopifyMoneyV2Fields}
  fragment ShopifyProductVariantFields on ProductVariant {
    id
    availableForSale
    compareAtPriceV2 {
      ...ShopifyMoneyV2Fields
    }
    priceV2 {
      ...ShopifyMoneyV2Fields
    }
  }
`

export const ShopifyProductFields = gql`
  ${ShopifyProductVariantFields}
  fragment ShopifyProductFields on Product {
    id
    handle
    variants(first: 100) {
      edges {
        node {
          ...ShopifyProductVariantFields
        }
      }
    }
  }
`

export const OrderLineItemFields = gql`
  ${ShopifyMoneyV2Fields}
  fragment OrderLineItemFields on OrderLineItem {
    quantity
    variant {
      id
      sku
      priceV2 {
        ...ShopifyMoneyV2Fields
      }
      product {
        id
        title
      }
    }
    customAttributes {
      key
      value
    }
  }
`

export const CustomerUserErrorsFields = gql`
  fragment CustomerUserErrorsFields on CustomerUserError {
    code
    field
    message
  }
`

export const CustomerFields = gql`
  ${ShopifyMoneyV2Fields}
  ${ShopifyMailingAddressFields}
  ${CheckoutDiscountFields}
  ${CheckoutFields}
  ${OrderLineItemFields}
  fragment CustomerFields on Customer {
    id
    defaultAddress {
      ...ShopifyMailingAddressFields
    }
    addresses(first: 100) {
      edges {
        node {
          ...ShopifyMailingAddressFields
        }
      }
    }
    displayName
    firstName
    lastName
    email
    phone
    lastIncompleteCheckout {
      ...CheckoutFields
    }
    orders(first: 100, sortKey: PROCESSED_AT, reverse: true) {
      edges {
        node {
          id
          discountApplications(first: 100) {
            edges {
              node {
                ...CheckoutDiscountFields
              }
            }
          }
          lineItems(first: 100) {
            edges {
              node {
                ...OrderLineItemFields
              }
            }
          }
          orderNumber
          processedAt
          successfulFulfillments {
            trackingCompany
            trackingInfo {
              number
              url
            }
          }
          subtotalPriceV2 {
            ...ShopifyMoneyV2Fields
          }
          totalTaxV2 {
            ...ShopifyMoneyV2Fields
          }
          totalShippingPriceV2 {
            ...ShopifyMoneyV2Fields
          }
          totalPriceV2 {
            ...ShopifyMoneyV2Fields
          }
        }
      }
    }
  }
`
