import gql from "graphql-tag"

export const GetCheckoutCartItemData = gql`
  query GetCheckoutCartItemData($ids: [ItemId!]) {
    allProducts(filter: { id: { in: $ids } }) {
      id
      slug
      productName
      images {
        url(imgixParams: { w: 160, h: 160 })
      }
      variants {
        id
        variantSku
        variantName
        price
        compareAtPrice
        options {
          optionType {
            name
          }
          optionValue
        }
        shopifyData
      }
      variantsJson
    }
  }
`

export const GetCustomerPurchasedProductsData = gql`
  query GetCustomerPurchasedProductsData($ids: [ItemId!]) {
    allProducts(filter: { id: { in: $ids } }) {
      id
      slug
      productName
      make {
        name
      }
      model {
        name
      }
      year
      images {
        url(imgixParams: { w: 160, h: 160 })
      }
      variants {
        id
        variantSku
        options {
          optionType {
            name
          }
          optionValue
        }
      }
      variantsJson
    }
  }
`
