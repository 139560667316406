import { ApolloClient } from "apollo-client"
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory"
import { HttpLink } from "apollo-link-http"
import fetchPonyfill from "fetch-ponyfill"

const { fetch } = fetchPonyfill()

const createApolloClient = () =>
  new ApolloClient({
    cache: new InMemoryCache({
      fragmentMatcher: new IntrospectionFragmentMatcher({
        introspectionQueryResultData: {
          __schema: {
            types: [],
          },
        },
      }),
    }),
    link: new HttpLink({
      uri: process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_ENDPOINT,
      headers: {
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token":
          process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_API_KEY,
      },
      fetch,
    }),
  })

export default createApolloClient
