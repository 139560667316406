import "react-responsive-carousel/lib/styles/carousel.min.css"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
// ^^ Move into components after:
// https://github.com/vercel/next.js/issues/12079#issuecomment-690581754

import "@assets/fonts/stylesheet.css"
import { Pixel } from "@components/Pixel"
import { AppProps } from "next/app"
import Router from "next/router"
import NProgress from "nprogress"
import "nprogress/nprogress.css"
import { useEffect, useState } from "react"
import { Provider } from "react-redux"
import "sanitize.css"
import { ThemeProvider } from "styled-components"
import store from "../redux/store"
import GlobalStyle from "../styles/GlobalStyle"
import Theme from "../styles/Theme"

// Sentry.init({
//   dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
//   environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
// })
NProgress.configure({ showSpinner: false, trickleSpeed: 50 })
let loadTimer

const onRouteChangeStart = () => {
  loadTimer = setTimeout(() => {
    NProgress.start()
  }, 250)
}

const onRouteChangeComplete = () => {
  clearTimeout(loadTimer)
  NProgress.done()
}

Router.events.on("routeChangeStart", onRouteChangeStart)
Router.events.on("routeChangeComplete", onRouteChangeComplete)
Router.events.on("routeChangeError", onRouteChangeComplete)

export default function App({ Component, pageProps }: AppProps) {
  const [isClient, setIsClient] = useState(false)
  // generate unique transactionId and set as Sentry tag
  const transactionId = Math.random().toString(36).substr(2, 9)

  // Sentry.configureScope((scope) => {
  //   scope.setTransactionName(transactionId)
  // })

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) {
    return null
  }

  return (
    <ThemeProvider theme={Theme}>
      <Pixel name="facebook" />
      <GlobalStyle />
      <Provider store={store}>
        <Component {...pageProps} />
      </Provider>
    </ThemeProvider>
  )
}
