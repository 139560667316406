import * as R from "ramda"

export const isNilOrEmpty = (a: any): any => R.isNil(a) || R.isEmpty(a)

export const getIn = (o: object, path: string[] | string, defaultValue?: any) =>
  (o &&
    path &&
    R.view(R.lensPath(typeof path === "string" ? path.split(".") : path))(o)) ||
  defaultValue

export const formatCommaSeparatedYears = (years: string) => {
  const yearsArr = years.split(",")
  const yearRanges = [] as any

  let currentRangeStart
  let currentRangeStep
  let currentRangeCount = 0

  yearsArr.forEach((year, i) => {
    const parsedYear = parseInt(year.trim())

    if (!currentRangeStart) {
      currentRangeStart = parsedYear
    } else if (currentRangeStart) {
      if (
        (!currentRangeStep && currentRangeStart + 1 === parsedYear) ||
        (currentRangeStep && currentRangeStep + 1 === parsedYear)
      ) {
        currentRangeStep = parsedYear
        currentRangeCount = currentRangeCount + 1

        if (i === yearsArr.length - 1) {
          yearRanges.push(
            `${currentRangeStart}-${currentRangeStart + currentRangeCount}`
          )
        }
      } else {
        if (currentRangeCount === 0) {
          yearRanges.push(currentRangeStart)
        } else {
          yearRanges.push(
            `${currentRangeStart}-${currentRangeStart + currentRangeCount}`
          )
        }

        if (i === yearsArr.length - 1) {
          yearRanges.push(parsedYear)
        } else {
          currentRangeStart = parsedYear
          currentRangeStep = undefined
          currentRangeCount = 0
        }
      }
    }
  })

  return yearRanges.length > 1 ? yearRanges.join(", ") : yearRanges[0]
}

export const toggleKeyInArray: any = (key, keys = []) =>
  !R.isEmpty(keys) && R.contains(key, keys)
    ? keys.filter((arrKey) => arrKey !== key)
    : [...keys, key]

export const delay = (ms) => {
  return new Promise((res) => setTimeout(res, ms))
}
