import { createModel } from "@rematch/core"
import { RootModel } from "./index"
import DefaultClient from "apollo-boost"
import createShopifyClient from "@shopify/createApolloClient"
import { GetCustomer } from "@shopify/graphql/queries"
import {
  CreateAccount,
  GetCustomerAccessToken,
  DeleteCustomerAccessToken,
  AssociateCheckout,
  CustomerAddressUpdate,
  CustomerAddressCreate,
  CustomerAddressDelete,
  CustomerDefaultAddressUpdate,
} from "@shopify/graphql/mutations"
import { LineItem, CustomerData } from "@shopify/types"
import createDatoClient from "@dato/createApolloClient"
import { GetCustomerPurchasedProductsData } from "@dato/graphql/queries"
import {
  ProductMap,
  PurchasedProducts,
  CustomerSignup,
  CustomerLogin,
} from "../types"
import { graphQLRequest, buildPurchasedProducts } from "../utils"
import { getIn, isNilOrEmpty } from "@utils/index"
import { getLocalStorage, writeLocalStorage } from "@utils/storage"
import { CUSTOMER_KEY } from "@constants/storageKeys"
import * as R from "ramda"

export interface CustomerState {
  customerAccessToken: string | null
  customerDataFetched: boolean
  data: CustomerData
  purchasedProducts: PurchasedProducts
}

const STUB = {
  id: "Z2lkOi8vc2hvcGlmeS9DdXN0b21lci8yMTQ4NzU2ODgxNDk2",
  defaultAddress: {
    id:
      "Z2lkOi8vc2hvcGlmeS9NYWlsaW5nQWRkcmVzcy8zMDUxMzk4NTk0NjQ4P21vZGVsX25hbWU9Q3VzdG9tZXJBZGRyZXNzJmN1c3RvbWVyX2FjY2Vzc190b2tlbj1zbjdmTzEyN3VnVjFtMFlQbmFlbHlXZXloNGQ2U1A4SHBFdXdfR2VpUU1pNXNhNzBORkVvSk93T1FUeHdrZXZfOVpmRnYyQ2ZNd3lnS25weDVTYXNmZ01VN2k0QWFXSThFQW9oYnVHb3dQYVJ2WVBTTkw2NE85QTZEMzBGVWNJbm0zU1JvTGZpWS1sY256SWpYMFJWV2VHb1o3RUw4MkkzUVByLVhDelg4ckRlZENkT3lkTWpBVFlXNm5QS3FRdEpPV2hCSGZESGNFb0M3TlhXVUprbFUwaGlJaXZCVlJ0QnJLcnNTcWZ0MmhqblNacFEwVEc3YU1xaHl2T3BOMmRh",
    firstName: "Viet",
    lastName: "Le",
    company: null,
    address1: "300 Spectrum Center Dr, Suite 1110",
    address2: "",
    city: "Irvine",
    country: "United States",
    province: "California",
    provinceCode: "CA",
    zip: "92618",
    phone: "(714) 363-2338",
    formatted: [
      "300 Spectrum Center Dr, Suite 1110",
      "Irvine CA 92618",
      "United States",
    ],
    __typename: "MailingAddress",
  },
  addresses: {
    edges: [
      {
        node: {
          id:
            "Z2lkOi8vc2hvcGlmeS9NYWlsaW5nQWRkcmVzcy8zMDUxMzA0MTI0NTA0P21vZGVsX25hbWU9Q3VzdG9tZXJBZGRyZXNzJmN1c3RvbWVyX2FjY2Vzc190b2tlbj1zcC1yYUV2LURDNU1VMTM2aVNHcWtrdS1DWVo0bDNmZU01UWpIZDEwNlR5MGNWcl9YYi1aRlZ2TVhzNkk0bUlBa0t5N3VvZHo3MjN5d2tPQmZKbXZIQWkyc1o0RjNjWmNrQ3J2WkZUX3VocXN0ME1tY050OHpfMV8yelFFT0d4RFJpMy1fNDZMLXNKWjgyRTl2aFBDcFVDVWlLdXhxLWJTbHNfZGpKSUYxN0dpTzFQR3hCc2NwblBPSFRrM3VTQmdCUGxZUkVnR1RWWERYZ25TbHR2c08tYzNpeTM4RGJRdXB4N01jQ09sdWpobDBHY2FNOW1FX0FwczF0Y0dfLU85",
          firstName: "Viet",
          lastName: "Le",
          company: null,
          address1: "300 Spectrum Center Dr, Suite 1110",
          address2: "",
          city: "Irvine",
          country: "United States",
          province: "California",
          provinceCode: "CA",
          zip: "92618",
          phone: "",
          formatted: [
            "300 Spectrum Center Dr, Suite 1110",
            "Irvine CA 92618",
            "United States",
          ],
          __typename: "MailingAddress",
        },
        __typename: "MailingAddressEdge",
      },
      {
        node: {
          id:
            "Z2lkOi8vc2hvcGlmeS9NYWlsaW5nQWRkcmVzcy8zMDUxMzIzMzU5MzIwP21vZGVsX25hbWU9Q3VzdG9tZXJBZGRyZXNzJmN1c3RvbWVyX2FjY2Vzc190b2tlbj1LVVphdGlWa2lFdlBrcUZSOWZTNXJVbkN4elFHVFZPc1ItaUhweWlFMmpRcVlFN2hZYVpEUDk5NWZsNXVDWnVwdm1TamZSb1haX0FoeTVzb05ENVFuN1BuSWJRb2liMjRqeXVLMm94cTdRSmpyUWJlOFcya21TYl9HU0JBSEFBc0NBWFA5aktMbXM1a3Y0REQ4SnY3TVFDVUxkMERQeUk0SEFfQkpsejNkeWUtbnhNcVQ0WFNyY2RRYUYzY05JMUpROWhyaDA0WXdqUDZzemU3YndIeER3RUtsZTdNd2pJbGxtVEt5UG9xWVc5cllQYUU4VmFEbHNrYmw5TldLdU5q",
          firstName: "Viet",
          lastName: "Le",
          company: "Vincit",
          address1: "300 Spectrum Center Dr, Suite 1110",
          address2: "",
          city: "Irvine",
          country: "United States",
          province: "California",
          provinceCode: "CA",
          zip: "92618",
          phone: "7143632338",
          formatted: [
            "Vincit",
            "300 Spectrum Center Dr, Suite 1110",
            "Irvine CA 92618",
            "United States",
          ],
          __typename: "MailingAddress",
        },
        __typename: "MailingAddressEdge",
      },
      {
        node: {
          id:
            "Z2lkOi8vc2hvcGlmeS9NYWlsaW5nQWRkcmVzcy8zMDUxMzk4NTk0NjQ4P21vZGVsX25hbWU9Q3VzdG9tZXJBZGRyZXNzJmN1c3RvbWVyX2FjY2Vzc190b2tlbj1zbjdmTzEyN3VnVjFtMFlQbmFlbHlXZXloNGQ2U1A4SHBFdXdfR2VpUU1pNXNhNzBORkVvSk93T1FUeHdrZXZfOVpmRnYyQ2ZNd3lnS25weDVTYXNmZ01VN2k0QWFXSThFQW9oYnVHb3dQYVJ2WVBTTkw2NE85QTZEMzBGVWNJbm0zU1JvTGZpWS1sY256SWpYMFJWV2VHb1o3RUw4MkkzUVByLVhDelg4ckRlZENkT3lkTWpBVFlXNm5QS3FRdEpPV2hCSGZESGNFb0M3TlhXVUprbFUwaGlJaXZCVlJ0QnJLcnNTcWZ0MmhqblNacFEwVEc3YU1xaHl2T3BOMmRh",
          firstName: "Viet",
          lastName: "Le",
          company: null,
          address1: "300 Spectrum Center Dr, Suite 1110",
          address2: "",
          city: "Irvine",
          country: "United States",
          province: "California",
          provinceCode: "CA",
          zip: "92618",
          phone: "(714) 363-2338",
          formatted: [
            "300 Spectrum Center Dr, Suite 1110",
            "Irvine CA 92618",
            "United States",
          ],
          __typename: "MailingAddress",
        },
        __typename: "MailingAddressEdge",
      },
    ],
    __typename: "MailingAddressConnection",
  },
  displayName: "Viet Le",
  firstName: "Viet",
  lastName: "Le",
  email: "viet.le@vincit.com",
  phone: null,
  lastIncompleteCheckout: {
    id:
      "Z2lkOi8vc2hvcGlmeS9DaGVja291dC82NmEwZDA5YTM1Mjg1YjAwNjg0MWViZGQ5ZmI3OTljMj9rZXk9ZDA4Y2JjMDY4MDZiYzY5NmMwMjg0NTJlZjkzNTNiNTQ=",
    note: "Checkout created via UAG v2",
    webUrl:
      "https://urban-armor-gear.myshopify.com/1212322/checkouts/66a0d09a35285b006841ebdd9fb799c2?key=d08cbc06806bc696c028452ef9353b54",
    completedAt: null,
    shippingAddress: {
      id:
        "Z2lkOi8vc2hvcGlmeS9NYWlsaW5nQWRkcmVzcy82NzExNDExMDE1NzY4P21vZGVsX25hbWU9QWRkcmVzcw==",
      firstName: "Viet",
      lastName: "Le",
      company: null,
      address1: "17021 Whitby Cir Apt 1",
      address2: "",
      city: "Tustin",
      country: "United States",
      province: "California",
      provinceCode: "CA",
      zip: "92780",
      phone: "7143632338",
      formatted: ["17021 Whitby Cir Apt 1", "Tustin CA 92780", "United States"],
      __typename: "MailingAddress",
    },
    discountApplications: {
      edges: [
        {
          node: {
            __typename: "DiscountCodeApplication",
          },
          __typename: "DiscountApplicationEdge",
        },
      ],
      __typename: "DiscountApplicationConnection",
    },
    lineItems: {
      edges: [
        {
          node: {
            id:
              "Z2lkOi8vc2hvcGlmeS9DaGVja291dExpbmVJdGVtLzMzMjYxODAzODk2OTIwMD9jaGVja291dD02NmEwZDA5YTM1Mjg1YjAwNjg0MWViZGQ5ZmI3OTljMg==",
            title: "[U] Anchor Series iPhone 12 Mini 5G Case",
            quantity: 1,
            variant: {
              id:
                "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMzI2MTgwMzg5NjkyMA==",
              sku: "11234M314040",
              product: {
                id: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ3MjgyNDc5MTA0ODg=",
                __typename: "Product",
              },
              __typename: "ProductVariant",
            },
            customAttributes: [
              {
                key: "_contentfulProductId",
                value: "2Gqv158iX1OOuOwjyHXCXC",
                __typename: "Attribute",
              },
              {
                key: "_contentfulVariantId",
                value: "5fHQA0RERBwOBL4W4SIJLy",
                __typename: "Attribute",
              },
              {
                key: "_productSlug",
                value: "u-anchor-series-iphone-12-mini-case",
                __typename: "Attribute",
              },
            ],
            __typename: "CheckoutLineItem",
          },
          __typename: "CheckoutLineItemEdge",
        },
        {
          node: {
            id:
              "Z2lkOi8vc2hvcGlmeS9DaGVja291dExpbmVJdGVtLzMzMjYxODAzOTI5Njg4MD9jaGVja291dD02NmEwZDA5YTM1Mjg1YjAwNjg0MWViZGQ5ZmI3OTljMg==",
            title: "[U] Anchor Series iPhone 12 Mini 5G Case",
            quantity: 1,
            variant: {
              id:
                "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMzI2MTgwMzkyOTY4OA==",
              sku: "11234M314747",
              product: {
                id: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ3MjgyNDc5MTA0ODg=",
                __typename: "Product",
              },
              __typename: "ProductVariant",
            },
            customAttributes: [
              {
                key: "_contentfulProductId",
                value: "2Gqv158iX1OOuOwjyHXCXC",
                __typename: "Attribute",
              },
              {
                key: "_contentfulVariantId",
                value: "UKfUBoobJ0LS1EXjp8q86",
                __typename: "Attribute",
              },
              {
                key: "_productSlug",
                value: "u-anchor-series-iphone-12-mini-case",
                __typename: "Attribute",
              },
            ],
            __typename: "CheckoutLineItem",
          },
          __typename: "CheckoutLineItemEdge",
        },
        {
          node: {
            id:
              "Z2lkOi8vc2hvcGlmeS9DaGVja291dExpbmVJdGVtLzMzMjYxODAzOTYyNDU2MD9jaGVja291dD02NmEwZDA5YTM1Mjg1YjAwNjg0MWViZGQ5ZmI3OTljMg==",
            title: "[U] Anchor Series iPhone 12 Mini 5G Case",
            quantity: 1,
            variant: {
              id:
                "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMzI2MTgwMzk2MjQ1Ng==",
              sku: "11234M314848",
              product: {
                id: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ3MjgyNDc5MTA0ODg=",
                __typename: "Product",
              },
              __typename: "ProductVariant",
            },
            customAttributes: [
              {
                key: "_contentfulProductId",
                value: "2Gqv158iX1OOuOwjyHXCXC",
                __typename: "Attribute",
              },
              {
                key: "_contentfulVariantId",
                value: "2lDoSGpu60PqkPP1i1vBUq",
                __typename: "Attribute",
              },
              {
                key: "_productSlug",
                value: "u-anchor-series-iphone-12-mini-case",
                __typename: "Attribute",
              },
            ],
            __typename: "CheckoutLineItem",
          },
          __typename: "CheckoutLineItemEdge",
        },
        {
          node: {
            id:
              "Z2lkOi8vc2hvcGlmeS9DaGVja291dExpbmVJdGVtLzMzMjYxODAzOTk1MjI0MD9jaGVja291dD02NmEwZDA5YTM1Mjg1YjAwNjg0MWViZGQ5ZmI3OTljMg==",
            title: "[U] Anchor Series iPhone 12 Mini 5G Case",
            quantity: 1,
            variant: {
              id:
                "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMzI2MTgwMzk5NTIyNA==",
              sku: "11234M313030",
              product: {
                id: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQ3MjgyNDc5MTA0ODg=",
                __typename: "Product",
              },
              __typename: "ProductVariant",
            },
            customAttributes: [
              {
                key: "_contentfulProductId",
                value: "2Gqv158iX1OOuOwjyHXCXC",
                __typename: "Attribute",
              },
              {
                key: "_contentfulVariantId",
                value: "3SsUBX97cfHDaSaHo77Sxq",
                __typename: "Attribute",
              },
              {
                key: "_productSlug",
                value: "u-anchor-series-iphone-12-mini-case",
                __typename: "Attribute",
              },
            ],
            __typename: "CheckoutLineItem",
          },
          __typename: "CheckoutLineItemEdge",
        },
      ],
      __typename: "CheckoutLineItemConnection",
    },
    lineItemsSubtotalPrice: {
      amount: "159.8",
      currencyCode: "USD",
      __typename: "MoneyV2",
    },
    subtotalPriceV2: {
      amount: "9.8",
      currencyCode: "USD",
      __typename: "MoneyV2",
    },
    shippingLine: null,
    totalTaxV2: {
      amount: "0.76",
      currencyCode: "USD",
      __typename: "MoneyV2",
    },
    totalPriceV2: {
      amount: "10.56",
      currencyCode: "USD",
      __typename: "MoneyV2",
    },
    __typename: "Checkout",
  },
  orders: {
    edges: [
      {
        node: {
          id:
            "Z2lkOi8vc2hvcGlmeS9PcmRlci8yNjYzOTQ1OTYxNTYwP2tleT04NDkxMjdlMjEyYjNkZWY1ZTQ0MTAzZjNiYTMzMGU3Nw==",
          discountApplications: {
            edges: [
              {
                node: {
                  __typename: "DiscountCodeApplication",
                },
                __typename: "DiscountApplicationEdge",
              },
            ],
            __typename: "DiscountApplicationConnection",
          },
          lineItems: {
            edges: [
              {
                node: {
                  quantity: 1,
                  variant: {
                    id:
                      "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8yMDQzODMzMDczNjcyOA==",
                    sku: "19148B114080",
                    priceV2: {
                      amount: "59.95",
                      currencyCode: "USD",
                      __typename: "MoneyV2",
                    },
                    product: {
                      id: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzIwNzMxMzQ5ODk0MDA=",
                      title: "Leather Watch Strap for Apple Watch",
                      __typename: "Product",
                    },
                    __typename: "ProductVariant",
                  },
                  customAttributes: [
                    {
                      key: "_contentfulProductId",
                      value: "3t9KokmIOz3Hnf4hg1kru4",
                      __typename: "Attribute",
                    },
                    {
                      key: "_contentfulVariantId",
                      value: "6JxZEE7briQSL6pe1ujYuo",
                      __typename: "Attribute",
                    },
                    {
                      key: "_productSlug",
                      value: "leather-watch-strap-for-apple-watch",
                      __typename: "Attribute",
                    },
                  ],
                  __typename: "OrderLineItem",
                },
                __typename: "OrderLineItemEdge",
              },
            ],
            __typename: "OrderLineItemConnection",
          },
          orderNumber: 580638,
          processedAt: "2020-09-24T20:50:22Z",
          successfulFulfillments: [
            {
              trackingCompany: "USPS",
              trackingInfo: [
                {
                  number: "92001901719446000007316057",
                  url:
                    "https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=92001901719446000007316057",
                  __typename: "FulfillmentTrackingInfo",
                },
              ],
              __typename: "Fulfillment",
            },
          ],
          subtotalPriceV2: {
            amount: "0.0",
            currencyCode: "USD",
            __typename: "MoneyV2",
          },
          totalTaxV2: {
            amount: "0.0",
            currencyCode: "USD",
            __typename: "MoneyV2",
          },
          totalShippingPriceV2: {
            amount: "0.0",
            currencyCode: "USD",
            __typename: "MoneyV2",
          },
          totalPriceV2: {
            amount: "0.0",
            currencyCode: "USD",
            __typename: "MoneyV2",
          },
          __typename: "Order",
        },
        __typename: "OrderEdge",
      },
      {
        node: {
          id:
            "Z2lkOi8vc2hvcGlmeS9PcmRlci8yMDIxMjUzNjc3MTQ0P2tleT05MmRjZmJlMjI5ZTNjOGM0Y2NmZjAwNGRkNmQzYjM3MA==",
          discountApplications: {
            edges: [
              {
                node: {
                  __typename: "DiscountCodeApplication",
                },
                __typename: "DiscountApplicationEdge",
              },
            ],
            __typename: "DiscountApplicationConnection",
          },
          lineItems: {
            edges: [
              {
                node: {
                  quantity: 1,
                  variant: {
                    id:
                      "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTY5NjU5OTE4NzU0NA==",
                    sku: "10225K114097",
                    priceV2: {
                      amount: "29.95",
                      currencyCode: "USD",
                      __typename: "MoneyV2",
                    },
                    product: {
                      id: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQzODY3ODY4MzY1Njg=",
                      title: "Silicone Case For Apple Airpods Pro",
                      __typename: "Product",
                    },
                    __typename: "ProductVariant",
                  },
                  customAttributes: [
                    {
                      key: "_contentfulProductId",
                      value: "3KszjIGI5zg4FubBZ69pHu",
                      __typename: "Attribute",
                    },
                    {
                      key: "_contentfulVariantId",
                      value: "4uoCh6oYE6N0pl7l4cwCTW",
                      __typename: "Attribute",
                    },
                    {
                      key: "_productSlug",
                      value: "silicone-case-for-apple-airpods-pro",
                      __typename: "Attribute",
                    },
                  ],
                  __typename: "OrderLineItem",
                },
                __typename: "OrderLineItemEdge",
              },
            ],
            __typename: "OrderLineItemConnection",
          },
          orderNumber: 491490,
          processedAt: "2020-01-10T03:34:25Z",
          successfulFulfillments: [],
          subtotalPriceV2: {
            amount: "0.0",
            currencyCode: "USD",
            __typename: "MoneyV2",
          },
          totalTaxV2: {
            amount: "0.0",
            currencyCode: "USD",
            __typename: "MoneyV2",
          },
          totalShippingPriceV2: {
            amount: "0.0",
            currencyCode: "USD",
            __typename: "MoneyV2",
          },
          totalPriceV2: {
            amount: "0.0",
            currencyCode: "USD",
            __typename: "MoneyV2",
          },
          __typename: "Order",
        },
        __typename: "OrderEdge",
      },
      {
        node: {
          id:
            "Z2lkOi8vc2hvcGlmeS9PcmRlci8yMDIxMTg3Mjg5MTc2P2tleT1kNGUyY2RiYzFmN2U0MDhhMWQzZDQyYjhkNmJlMmJmNg==",
          discountApplications: {
            edges: [
              {
                node: {
                  __typename: "DiscountCodeApplication",
                },
                __typename: "DiscountApplicationEdge",
              },
            ],
            __typename: "DiscountApplicationConnection",
          },
          lineItems: {
            edges: [
              {
                node: {
                  quantity: 1,
                  variant: {
                    id:
                      "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTY5NjU5OTE4NzU0NA==",
                    sku: "10225K114097",
                    priceV2: {
                      amount: "29.95",
                      currencyCode: "USD",
                      __typename: "MoneyV2",
                    },
                    product: {
                      id: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQzODY3ODY4MzY1Njg=",
                      title: "Silicone Case For Apple Airpods Pro",
                      __typename: "Product",
                    },
                    __typename: "ProductVariant",
                  },
                  customAttributes: [
                    {
                      key: "_contentfulProductId",
                      value: "3KszjIGI5zg4FubBZ69pHu",
                      __typename: "Attribute",
                    },
                    {
                      key: "_contentfulVariantId",
                      value: "4uoCh6oYE6N0pl7l4cwCTW",
                      __typename: "Attribute",
                    },
                    {
                      key: "_productSlug",
                      value: "silicone-case-for-apple-airpods-pro",
                      __typename: "Attribute",
                    },
                  ],
                  __typename: "OrderLineItem",
                },
                __typename: "OrderLineItemEdge",
              },
            ],
            __typename: "OrderLineItemConnection",
          },
          orderNumber: 491477,
          processedAt: "2020-01-10T02:38:02Z",
          successfulFulfillments: [],
          subtotalPriceV2: {
            amount: "0.0",
            currencyCode: "USD",
            __typename: "MoneyV2",
          },
          totalTaxV2: {
            amount: "0.0",
            currencyCode: "USD",
            __typename: "MoneyV2",
          },
          totalShippingPriceV2: {
            amount: "0.0",
            currencyCode: "USD",
            __typename: "MoneyV2",
          },
          totalPriceV2: {
            amount: "0.0",
            currencyCode: "USD",
            __typename: "MoneyV2",
          },
          __typename: "Order",
        },
        __typename: "OrderEdge",
      },
      {
        node: {
          id:
            "Z2lkOi8vc2hvcGlmeS9PcmRlci8yMDIxMTcwNTQ0NzI4P2tleT1mNDU5M2ZiYzI5NmE0MDFlMDRhYTBjNzU1ODA1YjJmOA==",
          discountApplications: {
            edges: [
              {
                node: {
                  __typename: "DiscountCodeApplication",
                },
                __typename: "DiscountApplicationEdge",
              },
            ],
            __typename: "DiscountApplicationConnection",
          },
          lineItems: {
            edges: [
              {
                node: {
                  quantity: 1,
                  variant: {
                    id:
                      "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMTY5NjU5OTE4NzU0NA==",
                    sku: "10225K114097",
                    priceV2: {
                      amount: "29.95",
                      currencyCode: "USD",
                      __typename: "MoneyV2",
                    },
                    product: {
                      id: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzQzODY3ODY4MzY1Njg=",
                      title: "Silicone Case For Apple Airpods Pro",
                      __typename: "Product",
                    },
                    __typename: "ProductVariant",
                  },
                  customAttributes: [
                    {
                      key: "_contentfulProductId",
                      value: "3KszjIGI5zg4FubBZ69pHu",
                      __typename: "Attribute",
                    },
                    {
                      key: "_contentfulVariantId",
                      value: "4uoCh6oYE6N0pl7l4cwCTW",
                      __typename: "Attribute",
                    },
                    {
                      key: "_productSlug",
                      value: "silicone-case-for-apple-airpods-pro",
                      __typename: "Attribute",
                    },
                  ],
                  __typename: "OrderLineItem",
                },
                __typename: "OrderLineItemEdge",
              },
            ],
            __typename: "OrderLineItemConnection",
          },
          orderNumber: 491472,
          processedAt: "2020-01-10T02:24:07Z",
          successfulFulfillments: [],
          subtotalPriceV2: {
            amount: "0.0",
            currencyCode: "USD",
            __typename: "MoneyV2",
          },
          totalTaxV2: {
            amount: "0.0",
            currencyCode: "USD",
            __typename: "MoneyV2",
          },
          totalShippingPriceV2: {
            amount: "0.0",
            currencyCode: "USD",
            __typename: "MoneyV2",
          },
          totalPriceV2: {
            amount: "0.0",
            currencyCode: "USD",
            __typename: "MoneyV2",
          },
          __typename: "Order",
        },
        __typename: "OrderEdge",
      },
      {
        node: {
          id:
            "Z2lkOi8vc2hvcGlmeS9PcmRlci8xMzE3NDQ0NDUyNDQwP2tleT1iYjkwZjc0MGE0NjU3MjA1YjFiNDIyMjIwMGNiMmI1Mw==",
          discountApplications: {
            edges: [
              {
                node: {
                  __typename: "DiscountCodeApplication",
                },
                __typename: "DiscountApplicationEdge",
              },
            ],
            __typename: "DiscountApplicationConnection",
          },
          lineItems: {
            edges: [
              {
                node: {
                  quantity: 1,
                  variant: null,
                  customAttributes: [],
                  __typename: "OrderLineItem",
                },
                __typename: "OrderLineItemEdge",
              },
              {
                node: {
                  quantity: 1,
                  variant: {
                    id:
                      "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8xNTQyMzAwMTA2NzYwOA==",
                    sku: "IPHX-SP",
                    priceV2: {
                      amount: "19.95",
                      currencyCode: "USD",
                      __typename: "MoneyV2",
                    },
                    product: {
                      id: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzE1NzEwNzU5NDg2MzI=",
                      title: "Glass Screen Shield iPhone Xs/X",
                      __typename: "Product",
                    },
                    __typename: "ProductVariant",
                  },
                  customAttributes: [],
                  __typename: "OrderLineItem",
                },
                __typename: "OrderLineItemEdge",
              },
              {
                node: {
                  quantity: 1,
                  variant: null,
                  customAttributes: [],
                  __typename: "OrderLineItem",
                },
                __typename: "OrderLineItemEdge",
              },
            ],
            __typename: "OrderLineItemConnection",
          },
          orderNumber: 412281,
          processedAt: "2019-07-27T00:16:59Z",
          successfulFulfillments: [
            {
              trackingCompany: "USPS",
              trackingInfo: [
                {
                  number: "9200190200499104451035",
                  url:
                    "https://urbanarmorgear.aftership.com/usps/9200190200499104451035",
                  __typename: "FulfillmentTrackingInfo",
                },
              ],
              __typename: "Fulfillment",
            },
            {
              trackingCompany: "USPS",
              trackingInfo: [
                {
                  number: "9205590200499104449356",
                  url:
                    "https://urbanarmorgear.aftership.com/usps/9205590200499104449356",
                  __typename: "FulfillmentTrackingInfo",
                },
              ],
              __typename: "Fulfillment",
            },
          ],
          subtotalPriceV2: {
            amount: "0.0",
            currencyCode: "USD",
            __typename: "MoneyV2",
          },
          totalTaxV2: {
            amount: "0.0",
            currencyCode: "USD",
            __typename: "MoneyV2",
          },
          totalShippingPriceV2: {
            amount: "0.0",
            currencyCode: "USD",
            __typename: "MoneyV2",
          },
          totalPriceV2: {
            amount: "0.0",
            currencyCode: "USD",
            __typename: "MoneyV2",
          },
          __typename: "Order",
        },
        __typename: "OrderEdge",
      },
    ],
    __typename: "OrderConnection",
  },
  __typename: "Customer",
}

const customer = createModel<RootModel>()({
  name: "customer",
  state: {
    customerAccessToken: getLocalStorage(CUSTOMER_KEY),
    customerDataFetched: false, // Fetch Customer data once
    purchasedProducts: {},
    data: {} as CustomerData,
    // data: STUB as any,
  } as CustomerState,
  reducers: {
    setCustomerAccessToken(state: CustomerState, customerAccessToken: string) {
      return {
        ...state,
        customerAccessToken,
      }
    },

    setCustomerDataFetched(state: CustomerState, customerDataFetched: boolean) {
      return {
        ...state,
        customerDataFetched,
      }
    },

    updateCustomerData(state: CustomerState, data: CustomerData) {
      return {
        ...state,
        data,
      }
    },

    removeCustomerData(state: CustomerState) {
      return {
        ...state,
        data: {} as CustomerData,
      }
    },

    updatePurchasedProducts(
      state: CustomerState,
      purchasedProducts: PurchasedProducts
    ) {
      return {
        ...state,
        purchasedProducts: {
          ...state.purchasedProducts,
          ...purchasedProducts,
        },
      }
    },
  },
  effects: (dispatch: any) => ({
    updateCustomerAccessToken(customerAccessToken) {
      writeLocalStorage(CUSTOMER_KEY, customerAccessToken)
      dispatch.customer.setCustomerAccessToken(customerAccessToken)
    },

    removeCustomerAccessToken() {
      writeLocalStorage(CUSTOMER_KEY, null)
      dispatch.customer.setCustomerAccessToken(null)
    },

    async createAccount(input: CustomerSignup) {
      const client: DefaultClient<any> = createShopifyClient()
      const { data, errors } = await graphQLRequest(client.mutate, {
        mutation: CreateAccount,
        variables: { input },
      })

      const customerData = getIn(data, "customerCreate.customer", null)
      const userErrors = getIn(data, "customerCreate.customerUserErrors", null)

      if (customerData) {
        dispatch.customer.updateCustomerData(customerData)
        dispatch.customer.loginCustomer({
          email: input.email,
          password: input.password,
        })
        return { success: true, error: "" }
      } else if (userErrors && userErrors.length > 0) {
        return { success: false, error: "Account already exists" }
      } else {
        return {
          success: false,
          error: "We encounterd an issue. Please try again.",
        }
      }
    },

    async loginCustomer(input: CustomerLogin, rootState) {
      const client: DefaultClient<any> = createShopifyClient()
      const { data, errors } = await graphQLRequest(client.mutate, {
        mutation: GetCustomerAccessToken,
        variables: { input },
      })

      const customerAccessToken = getIn(
        data,
        "customerAccessTokenCreate.customerAccessToken.accessToken",
        null
      )

      const loginErrors = getIn(
        data,
        "customerAccessTokenCreate.userErrors",
        []
      )

      if (customerAccessToken) {
        dispatch.customer.updateCustomerAccessToken(customerAccessToken)
        if (rootState.checkout.checkoutId) {
          dispatch.customer.associateCheckoutId(rootState.checkout.checkoutId)
        }
        return { success: true, error: "" }
      } else if (errors) {
        return {
          success: false,
          error: "We encountered an issue. Please try again.",
        }
      } else if (loginErrors && loginErrors.length > 0) {
        return { success: false, error: "Incorrect email or password." }
      }
      return { success: false, error: "Something went wrong." }
    },

    async logoutCustomer(customerAccessToken) {
      const client: DefaultClient<any> = createShopifyClient()
      const result = await graphQLRequest(client.mutate, {
        mutation: DeleteCustomerAccessToken,
        variables: { customerAccessToken },
      })

      dispatch.customer.removeCustomerAccessToken()
      dispatch.customer.removeCustomerData()
      return result
    },

    async fetchCustomerData(customerAccessToken, rootState) {
      if (customerAccessToken) {
        const client: DefaultClient<any> = createShopifyClient()
        const { data, errors } = await graphQLRequest(client.query, {
          query: GetCustomer,
          variables: { customerAccessToken },
        })

        const customerData = getIn(data, "customer")
        if (customerData) {
          const customerCheckout = getIn(
            customerData,
            "lastIncompleteCheckout",
            null
          )

          if (
            !rootState.checkout.checkoutId &&
            customerCheckout &&
            customerCheckout.id
          ) {
            const lineItems = getIn(customerCheckout, "lineItems.edges", [])
            const invalidLineItem =
              !R.isEmpty(lineItems) &&
              R.find((item: LineItem) =>
                isNilOrEmpty(getIn(item, "node.variant"))
              )(customerCheckout.lineItems.edges)
            const customerCheckoutIsValid =
              customerCheckout &&
              !customerCheckout.completedAt &&
              !invalidLineItem

            if (customerCheckoutIsValid) {
              dispatch.checkout.fetchCheckout(customerCheckout.id)
            }
          }

          dispatch.customer.updateCustomerAccessToken(customerAccessToken)
          dispatch.customer.updateCustomerData(customerData)
          return true
        } else {
          dispatch.customer.removeCustomerAccessToken()
          dispatch.customer.removeCustomerData()
        }
        dispatch.customer.setCustomerAccessToken(true)
      }
      return false
    },

    associateCheckoutId(checkoutId, rootState: any) {
      const customerAccessToken = rootState.customer.customerAccessToken
      if (customerAccessToken) {
        const client: DefaultClient<any> = createShopifyClient()
        return graphQLRequest(client.mutate, {
          mutation: AssociateCheckout,
          variables: {
            customerAccessToken,
            checkoutId,
          },
        })
      }
      return undefined
    },

    async addressCreate({ setAsDefault, ...address }, rootState: any) {
      const customerAccessToken = rootState.customer.customerAccessToken

      if (customerAccessToken) {
        const client: DefaultClient<any> = createShopifyClient()
        const result = await graphQLRequest(client.mutate, {
          mutation: CustomerAddressCreate,
          variables: {
            customerAccessToken,
            address,
          },
        })

        dispatch.customer.fetchCustomerData(customerAccessToken)

        const defaultAddress = rootState.customer.data.defaultAddress

        if (!defaultAddress || setAsDefault) {
          const createdId = getIn(
            result,
            "data.customerAddressCreate.customerAddress.id",
            ""
          )
          dispatch.customer.updateDefaultAddress(createdId)
        }

        return result
      }
      return undefined
    },

    async addressUpdate(
      { __typename, id, setAsDefault, ...address },
      rootState: any
    ) {
      const customerAccessToken = rootState.customer.customerAccessToken
      if (customerAccessToken) {
        const client: DefaultClient<any> = createShopifyClient()
        const result = await graphQLRequest(client.mutate, {
          mutation: CustomerAddressUpdate,
          variables: {
            id,
            customerAccessToken,
            address,
          },
        })
        dispatch.customer.fetchCustomerData(customerAccessToken)

        const defaultAddress = rootState.customer.data.defaultAddress

        if (!defaultAddress || (setAsDefault && defaultAddress.id !== id)) {
          dispatch.customer.updateDefaultAddress(id)
        }

        return result
      }
      return undefined
    },

    async addressDelete(addressId, rootState: any) {
      const customerAccessToken = rootState.customer.customerAccessToken
      if (customerAccessToken) {
        const client: DefaultClient<any> = createShopifyClient()
        const result = await graphQLRequest(client.mutate, {
          mutation: CustomerAddressDelete,
          variables: {
            id: addressId,
            customerAccessToken,
          },
        })

        dispatch.customer.fetchCustomerData(customerAccessToken)

        return result
      }
      return undefined
    },

    async updateDefaultAddress(addressId, rootState: any) {
      const customerAccessToken = rootState.customer.customerAccessToken
      if (customerAccessToken) {
        const client: DefaultClient<any> = createShopifyClient()
        const result = await graphQLRequest(client.mutate, {
          mutation: CustomerDefaultAddressUpdate,
          variables: {
            addressId,
            customerAccessToken,
          },
        })

        dispatch.customer.fetchCustomerData(customerAccessToken)

        return result
      }
      return undefined
    },

    async fetchPurchasedProducts(ids, productMap: ProductMap) {
      const client: DefaultClient<any> = createDatoClient()
      const { data, errors } = await graphQLRequest(client.query, {
        query: GetCustomerPurchasedProductsData,
        variables: { ids },
      })

      const products = getIn(data, "allProducts", [])
      const purchasedProducts = buildPurchasedProducts(products, productMap)

      dispatch.customer.updatePurchasedProducts(purchasedProducts)
    },
  }),
})

export default customer
