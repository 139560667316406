export default {
  mobile: 320,
  mobileLarge: 412,
  mobileXL: 450,
  tablet: 768,
  tabletLarge: 820,
  laptop: 1024,
  desktop: 1440,
  maxWidth: 1680,
  widescreen: 2560,
}
