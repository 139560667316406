import gql from "graphql-tag"
import { CheckoutFields, CustomerFields } from "./fragments"

export const GetCheckout = gql`
  ${CheckoutFields}
  query GetCheckout($checkoutId: ID!) {
    node(id: $checkoutId) {
      ...CheckoutFields
    }
  }
`

export const GetCustomer = gql`
  ${CustomerFields}
  query GetCustomer($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      ...CustomerFields
    }
  }
`

export const GetProductById = gql`
  query getProductById($id: ID!) {
    product(id: $id) {
      title
      status
      totalVariants
    }
  }
`
