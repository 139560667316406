import { createGlobalStyle } from "styled-components"
import colors from "@constants/colors"

const GlobalStyle = createGlobalStyle`
  html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  body {
    background-color: #ffffff;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
  }

  body.noScroll {
    overflow: hidden;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  button,
  div {
    margin: 0;
    font-size: inherit;
  }

  a {
    text-decoration: inherit;
    color: inherit;
  }

  button,
  select,
  input,
  textarea {
    font: inherit;
  }

  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    z-index: 9000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: ${colors.primary};
  }

  #nprogress .peg {
    position: absolute;
    right: 0px;
    display: block;
    opacity: 1;
    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px ${colors.primary}, 0 0 5px ${colors.primary};
  }
`

export default GlobalStyle
