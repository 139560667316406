import React from "react"
import Head from "next/head"
import { FacebookPixel } from "./facebook/index"

export interface PixelProps {
  name: string
}

export const Pixel: React.FC<PixelProps> = ({ name }) => {
  console.log("Pixel", name, name === "facebook")
  return <>{name === "facebook" && <FacebookPixel />}</>
}
